import React from 'react';
import { Routes, Route } from "react-router-dom";

import { Home } from "./pages/home";

import "./styles/index.css";

function App() {

  React.useEffect(() => {
    console.clear();
    console.log('%cHOLD UP THERE!', 'color: #e96665; font-weight: bold;; font-size: 50px');
    console.log(
      "%cIf someone told you to paste something here, it's VERY likely you're being scammed.",
      'color: red; font-size: 20px'
    );
    console.log('%cPasting something here could potentially be very dangerous!', 'color: red; font-size: 25px');
  }, []);

  return (

    <main className="dark text-foreground bg-zinc-950 overflow-hidden">

      <Routes>
        {/* Routes */}
        <Route path='/' element={<Home></Home>} />
      </Routes>

    </main>

  );

};

export default App;
