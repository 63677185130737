import React from 'react';
import { FaDotCircle } from 'react-icons/fa';

import { FaEnvelope, FaPhone } from "react-icons/fa6";

export const Home = () => {
  
  return (
    <>
      <title>Stocade - Home</title>

      {/* Navbar */}

      <div className='z-20 absolute p-10 top-0 left-0'>
        <div>
          <h1 className='text-shadow phone:text-[10vw] text-[#a689c1] text-[30px] m-0 uppercase tracking-normal leading-[0.8] font-bold'>Stocade</h1>
        </div>
      </div>

      {/* Hero */}

      <div className='relative w-screen h-[500px] mt-[100px]'>

        <div className='hero-image'>
        </div>

        {/*<img src='/background2.jpg' className='min-h-[650px] aspect-video absolute z-0 blur-sm top-0' />*/}

        <div className='big:p-[80px] p-[40px] rounded-2xl z-10 relative'>

          <div className='w-full flex flex-col pt-[250px]'>
            <h1 className='text-shadow phone:text-[18vw] text-[#a689c1] text-[120px] m-0 uppercase tracking-normal leading-[0.8] font-bold'>Stocade</h1>
            <p className='text-shadow phone:text-[5vw] text-white text-[30px] m-0 leading-[1]' style={{fontFamily: "Raleway Semibold"}}>operated by Jere Kiitola</p>
          </div>

        </div>

      </div>

      {/* About */}

      <div className='p-[40px] bg-gray-200 z-20 relative'>

        <div className='w-full flex flex-col py-10'>
          <h1 className='phone:text-[10vw] text-[#a689c1] text-[70px] m-0 uppercase tracking-normal leading-[0.8] font-bold'>About Stocade</h1>
          <p className='phone:text-[5vw] text-gray-700 text-[30px] m-0 leading-[1]' style={{fontFamily: "Raleway Semibold"}}>
            Stocade is a sole trader company operated by Jere Kiitola. He is a passionate and experienced System Administrator/Network Engineer based in Finland.
            <br />
            <br />
            Stocade specialises in providing top-notch IT consultation services, including BGP routing, VPN setups, and SD-WAN deployment to VLAN configuration(s). 
          </p>
        </div>

      </div>

      {/* Footer */}

      <div className='flex flex-col items-center gap-[20px] p-[40px] bg-white'>
        <h1 className='phone:text-[10vw] text-[#a689c1] text-[70px] m-0 uppercase tracking-normal leading-[0.8] font-bold'>Stocade</h1>
        <div className='phone:gap-[3vw] flex flex-row items-center gap-[20px] font-semibold'>
          <p className='phone:text-[3vw] text-gray-700 text-[20px] m-0 leading-[1]'>
          Aarikkalankatu 10 D 18, 33530, Tampere, Finland
          </p>

          <FaDotCircle className='text-gray-700 text-[8px]' />

          <div className='phone:gap-[1vw] flex flex-row gap-[10px] items-center'>
            <FaEnvelope className='phone:text-[3vw] text-gray-700 text-[20px]' />
            <a className='phone:text-[3vw] text-gray-700 text-[20px]' href='mailto:noc@stocade.com'>noc@stocade.com</a>
          </div>
        </div>

        <div className='phone:gap-[1vw] flex flex-row gap-[10px] items-center font-semibold'>
            <FaPhone className='phone:text-[3vw] text-gray-700 text-[20px]' />
            <a className='phone:text-[3vw] text-gray-700 text-[20px]' href='tel:+358456988882'>+35 84 5698 8882 (FI)</a>
        </div>

        <p className='phone:text-[2vw] text-gray-700 text-[15px] font-bold'>© 2024 Stocade. All rights reserved.</p>
        
      </div>



    </>
  )

};